<template>
  <v-theme-provider>
    <base-section
      id="info-alt"
      class="py-10"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-img
              :src="require('@/assets/Ronghao_logo_appbar.png')"
              :height="$vuetify.breakpoint.mdAndUp ? 300 : 200"
              contain
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <base-business-contact />
          </v-col>
        </v-row>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3206.9594938466907!2d117.87000507541455!3d36.50683987233217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35eaf28aacb3c92f%3A0xd0069da01ab9d65b!2sQing%20Long%20Shan%20Lu%2C%20Zi%20Bo%20Shi%2C%20Shan%20Dong%20Sheng%2C%20%EC%A4%91%EA%B5%AD!5e0!3m2!1sko!2smy!4v1686205913537!5m2!1sko!2smy"
          width="100%"
          height="500"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        />
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionBusinessInfo',
  }
</script>
